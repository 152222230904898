import React from "react";
import "./what_is.css";

const WhatIs = () => {
  return (
    <div className="what_is_conatiner">
      <div className="what_is_header">What is AccelerateX ?</div>
      <div className="what_is_heade_small_screen">
        What is
        <br /> AccelerateX ?
      </div>
      <div className="what_is_desc">
        Accelerator-X is redefining the life of Indian youth by tackling
        outdated curriculum gaps, bringing
        <br />
        students closer to real industry connections and hands-on opportunities.
        We enable them with the skills needed to thrive in today’s fast-paced
        world, making them ready for the challenges of tomorrow.
      </div>
      <div className="what_is_desc_small_screen">
        Accelerator-X is redefining the life of Indian
        <br />
        youth by tackling outdated curriculum gaps,
        <br />
        bringing students closer to real industry
        <br />
        connections and hands-on opportunities. We
        <br />
        enable them with the skills needed to thrive in
        <br />
        today’s fast-paced world, making them ready
        <br />
        for the challenges of tomorrow.
      </div>
      <div className="what_is_items">
        <div className="what_is_item">
          <div className="what_is_item_text">Enhance Employability </div>
        </div>
        <div className="what_is_item">
          <div className="what_is_item_text"> Industry Immersion</div>
        </div>
        <div className="what_is_item">
          <div className="what_is_item_text">Practical Approach</div>
        </div>
        <div className="what_is_item">
          <div className="what_is_item_text">Professional Advancement</div>
        </div>
      </div>
      <div className="what_is_items_small_screen">
        <div className="what_is_items_small_screen_1">
          <div className="what_is_item">
            <div className="what_is_item_text">Enhance Employability </div>
          </div>
          <div className="what_is_item">
            <div className="what_is_item_text"> Industry Immersion</div>
          </div>
        </div>
        <div className="what_is_items_small_screen_2">
          <div className="what_is_item">
            <div className="what_is_item_text">Practical Approach</div>
          </div>
          <div className="what_is_item">
            <div className="what_is_item_text">Professional Advancement</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIs;
