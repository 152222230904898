import React from "react";
import "./topLogoSection.css";
import TopMainBg from "../../asset/topMainBg.png";
import Logo from "../../asset/logo.png";

const TopLogoSection = () => {
  return (
    <div className="top_main_container">
      <img src={TopMainBg} alt="img" className="top_min_bg_img" />
      <img src={Logo} alt="logo" className="top_main_logo" />
      <div className="top_heading_container">
        Get Employed,
        <br />
        Change Your Future.
      </div>
    </div>
  );
};

export default TopLogoSection;
