import React from "react";
import "./homeScreen.css";
import TopLogoSection from "../../component/topLogoSection/TopLogoSection";
import LeftBg from "../../asset/leftBg.png";
import RightBg from "../../asset/rightBg.png";
import Add from "../../component/add/Add";
import WhatIs from "../../component/what_is/WhatIs";
import Images from "../../component/images/Images";
import Speakers from "../../asset/speakers.png";
import Phone from "../../asset/phone.png";
import Mail from "../../asset/mail.png";
import Insta from "../../asset/insta.png";
import SpeakerSmall from "../../asset/people_small.png";

const HomeScreen = () => {
  return (
    <div className="homeScreen_main_container">
      <TopLogoSection />

      <div
        style={{ backgroundImage: `url(${LeftBg})` }}
        className="left_bg_container"
      >
        <div className="homeScreen_add_container">
          <Add />
          <WhatIs />
          <Images />
        </div>
      </div>

      <div
        style={{ backgroundImage: `url(${RightBg})` }}
        className="right_bg_container"
      >
        <div className="homeScreen_people_container">
          <div className="people_container">
            <div className="people_header_text">
              Meet the Visionaries
              <br />
              <span style={{ fontSize: "32px", fontWeight: "400" }}>
                Supporting us
              </span>
            </div>
            <div className="people_image_container">
              <img
                src={Speakers}
                alt="people_image_img"
                className="speakers_img"
              />

              <img
                src={SpeakerSmall}
                alt="people_image_img"
                className="speakers_img_small"
              />
            </div>
          </div>

          <div className="home_screen_footer_container">
            <div className="footer_header">Contact Us</div>
            <div className="contact_items_container">
              <div className="contact_item_container">
                <img src={Phone} alt="cont_img" className="contact_img" />
                <div className="conatct_text">+91 77367 29997</div>
              </div>

              <a
                href="mailto:Hi@theacceleratex.com"
                target="_blank"
                rel="noopener noreferrer"
                className="contact_item_container"
                style={{ textDecoration: "none", color: "white" }}
              >
                <img src={Mail} alt="cont_img" className="contact_img" />
                <div className="conatct_text">Hi@theacceleratex.com</div>
              </a>

              <a
                href="https://www.instagram.com/theacceleratex/"
                target="_blank"
                rel="noopener noreferrer"
                className="contact_item_container"
                style={{ textDecoration: "none", color: "white" }}
              >
                <img src={Insta} alt="cont_img" className="contact_img" />
                <div className="conatct_text">theacceleratex</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
