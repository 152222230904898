import "./add.css";
import People from "../../asset/people.png";

const Add = () => {
  return (
    <div className="add_container">
      <div className="add_text_conatainer">
        The future of global employability is
        <br />
        changing, and India's massive
        <br />
        youth is underprepared
        <br />
        but not anymore.
      </div>

      <div className="add_img_container">
        <img src={People} alt="people_img" className="people_img" />
      </div>

      <div className="add_text_conatainer_small_screen">
        The future of global employability is
        <br />
        changing, and India's massive youth is
        <br /> underprepared but not anymore.
      </div>
    </div>
  );
};

export default Add;
