import React from "react";
import "./image.css";
import AiSummit from "../../asset/aiSummit.png";
import Image1 from "../../asset/image1.png";
import Image2 from "../../asset/image2.png";
import Image3 from "../../asset/image3.png";
import Image4 from "../../asset/image4.png";
import Image5 from "../../asset/image5.png";
import Image6 from "../../asset/image6.png";

const Images = () => {
  return (
    <div className="images_container">
      <div className="images_heading_container">
        <img src={AiSummit} alt="ai_summit_img" className="ai_summit_img" />
      </div>

      <div className="image_container">
        <div className="image_container_first">
          <div className="image_container_first_1">
            <img src={Image1} alt="image1" className="image_item_img" />
          </div>
          <div className="image_container_first_2">
            <div className="image_container_first_2_1">
              <img src={Image2} alt="image2" className="image_item_img" />
            </div>
            <div className="image_container_first_2_2">
              <img src={Image3} alt="image3" className="image_item_img" />
            </div>
          </div>
        </div>
        <div className="image_container_second">
          <img src={Image4} alt="image4" className="image_item_img" />
        </div>
        <div className="image_container_third">
          <div className="image_container_third_1">
            <img src={Image5} alt="image5" className="image_item_img" />
          </div>
          <div className="image_container_third_2">
            <img src={Image6} alt="image6" className="image_item_img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Images;
